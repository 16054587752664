import styled from 'styled-components';

const Delimiter = styled.hr`
  display: block;
  margin: 1.5em 0;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral4};
`;

export default Delimiter;
